// DisconnectModal.js
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;

const DisconnectModal = ({ show, onHide, onConfirm, title, message, userId, isAssistantEnabled }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true); // Indica que está en proceso la solicitud
    try {
      if(isAssistantEnabled === 1) {
        await axios.put(`${ROOT_URL}/api/instagram/assistant/changeStatus`, {
          id: userId,
          status: 0
        });
        console.log('Estado actualizado en la base de datos.');
      }
      const response = await axios.delete(`${ROOT_URL}/api/instagram/deleteAuth`, {
        data: { userId }, // Enviar el ID del usuario como parte del cuerpo de la petición
      });
      console.log("Información de autenticación eliminada exitosamente.");

      if (response.status === 200) {
        onConfirm(); // Llama a la función de confirmación después de la eliminación exitosa
      }
    } catch (error) {
      console.error("Error al eliminar la información de autenticación:", error);
      // Puedes mostrar un mensaje de error en la interfaz si lo deseas
    } finally {
      setLoading(false); // Finaliza el proceso de carga
      onHide(); // Cierra el modal
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={loading}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleConfirm} disabled={loading}>
          {loading ? "Procesando..." : "Confirmar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisconnectModal;