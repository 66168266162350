import AuthActions from "./authActions";
import ProfileActions from "../profile/profileActions";
import PcActions from "../pageConfiguration/pcActions";
import budgetActions from "../budget/budgetActions";
import { stylesAplication } from "../../utils/stylesAplication";
import modeJson from "../../components/Profile/components/DarkMode/darkModeJson.json";

import {
  saveAuthUser,
  loginAuthUser,
  signAuthUser,
  loadStore,
} from "../../helpers/authHelper";
import {currencyActions} from "../currency/currencyActions";
import {currencyByIso, getCurrencies} from "../../helpers/currencyHelper";

const bootstrap = async (dispatch, response) => {
  dispatch(AuthActions.setLogin(response.data));
  dispatch(ProfileActions.setLoadStore(response.data.user));
  dispatch(PcActions.setLoadStorePC(response.data.pageConfiguration));
  dispatch(AuthActions.setInstagramAuth(response.data));
  dispatch(
      budgetActions.setLoadStoreBudget(response.data.budget, response.data.user)
  );

  const currencies = await getCurrencies();
  dispatch(currencyActions.setCurrencies(currencies));
  dispatch(currencyActions.setCurrencySelected(currencyByIso(currencies, response.data.pageConfiguration.resultInformation[0].currency)));

  if (response.data.user.user[0].theme === 1) {
    await stylesAplication(modeJson.darkModeJson);
  }
}

export const AuthOperations = {
  setLogin: (data) => async (dispatch) => {
    const response = await loginAuthUser(data);
    console.log(response);
    if (response.data.user) {
      await saveAuthUser(response.data.user.user[0], data.token);
      await bootstrap(dispatch, response);
    }
    // if (response.data?.messagePass) {
    if (response.data?.error) {
      dispatch(AuthActions.setError(response.data));
    }
    // if (response.data.messageEmail) {
    if (response.data?.error) {
      dispatch(AuthActions.setError(response.data));
    }
  },

  setSignUp: (data) => async (dispatch) => {
    const response = await signAuthUser(data);
    dispatch(AuthActions.setLoadError(response));
    await saveAuthUser(response.data.user.user[0], data.token);
    await bootstrap(dispatch, response);
  },

  setLoadStore: (id) => async (dispatch) => {
    const response = await loadStore(id);
    await bootstrap(dispatch, response);

  },
  logout: () => async (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  },
};
