import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Modal, Container } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { BudgetEmpty } from "../Budget/components/BudgetEmpty";
import { PCShared } from "./components/PC_Shared/PC_Shared";
import { ToastConfirmation } from "../Alerts/ToastConfirmation";
import { ButtonInkup } from "../Button/ButtonInkup";
import { PCInformation } from "./components/PC_Information/PC_Information";
import { PCProfile } from "./components/PC_Profile/PC_Profile";
import { PCOptions } from "./components/PC_Options/PC_Options";
import { PCArtist } from "./components/PC_Artist/components/PC_Artist";
import { PCServices } from "./components/PC_Services/PC_Services";
import eyeIcon from "../../assets/images/icons/icon-eye.svg";
import { PageConfigurationData } from "./PageConfigurationData";
import { InstagramButton } from "./components/Instagram/Instagram";

export const PageConfigurationChat = ({ pCInfo, user, showAlerts }) => {
  const { id, id_rol } = user.profile;
  const pcInfo = pCInfo.PCInformation;
  const [t] = useTranslation("global");
  const [openModal, setOpenModal] = useState(false);
  let arrayData = PageConfigurationData(id_rol);
  const ROOT_URL = process.env.REACT_APP_MY_URL;

  return (
    <Container fluid className="containerPageConfiguration">
      <Row translate="no" className="rowFixedHeaderPc">
        <Col>
          <div className="header-pc">
            <h2 className=" headingH2 text-color">
              {t("information.pageConfiguration")}
            </h2>
            <ButtonInkup
              buttonId="idButtonPreviewPageConfigurationChat"
              buttonClass={"positionButton greenLargeCopyButton reverseImg"}
              image={<img className="whiteFilter" src={eyeIcon} alt="eye" />}
              handle={() =>
                window.open(
                  `${ROOT_URL}/` + pCInfo.PCInformation.domain_name,
                  "_blank"
                )
              }
              text={t("information.previewYourPage")} 
            />
            <Toaster />
          </div>
        </Col>
        <hr className="color-hr" />
      </Row>
      <Row translate="no" className="rowFixedHeaderPcLink">
        <Col className="p-0">
          <BudgetEmpty pcInfo={pcInfo} />
          <hr className="color-hr mt-0" />
        </Col>
      </Row>
      <Row className="rowAccordionPageConfiguration">
        <Col className="p-customPC">
          <InstagramButton pcInfo={pCInfo}/>
          <PCInformation arrayData={arrayData} /> 
          <PCProfile
            user={user}
            pCInfo={pCInfo}
            textTitleProfile={t("pageConfiguration.titleProfile")}
          />

          <PCOptions
            id={id}
            id_rol={id_rol}
            theme={user.profile.theme}
            PCInformation={pCInfo}
            textTitleOptionsForm={t("pageConfiguration.optionsFormTitle")}
          />

          {id_rol !== 4 && (
            <PCArtist
              theme={user.profile.theme}
              PCInformation={pCInfo}
              id={user.profile.id}
            />
          )}

          <PCServices
            theme={user.profile.theme}
            id={id}
            id_rol={id_rol}
            PCInformation={pCInfo}
            optionsFormServices={t("pageConfiguration.optionsFormServices")}
            otherServices={t("pageConfiguration.otherServices")}
            textButtonSave={t("information.save")}
          />
        </Col>
      </Row>
      <Modal id="Modal RRSS" className="modalShare" show={openModal}>
        <PCShared setOpenModal={setOpenModal} pcInfo={pCInfo.PCInformation} />
      </Modal>
      <ToastConfirmation showAlerts={showAlerts} />
    </Container>
  );
};
