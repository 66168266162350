import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { NavbarMenu } from "../../components/Navbar/Navbar";
import { PageConfigurationChat } from "../../components/PageConfigurationChat/PageConfigurationChat";
import { Profile } from "../../components/Profile/Profile";
import { Budget } from "../../components/Budget/Budget";
import { Suggestions } from "../../components/Suggestions/Suggestions";
import { ModalCookies } from "../../components/Cookies/ModalCookies";
import { StripeLinks } from "../../components/Profile/components/Stripe/Stripe";
import { InstagramAuthentication } from "../../components/PageConfigurationChat/InstagramAuthentication";
import ScrollToTop from "../../components/ScrollToTop";

export default function PrivateRoute({ state, cookies }) {
  const [sidebarUsed, setSidebarUsed] = useState("inicio");
  const location = useLocation();


  const isInstagramAuthRoute = location.pathname === "/instagramAuthentication";
  return (

    <Container fluid className="bg-mode">
      <Row>
        <ModalCookies cookies={cookies} />
      </Row>
      <Row>
      {!isInstagramAuthRoute && (
        <>
        <Sidebar
          className="fontFamilyApp"
          setSidebarUsed={setSidebarUsed}
          sidebarUsed={sidebarUsed}
          // open={open}
          state={state}
        />
        <NavbarMenu state={state} />
        </>
      )}
        <Col className="fontFamilyApp minHeightBudget d-md-block scroll-position">
          <ScrollToTop />
          <Routes>
            <Route
              exact
              path="/perfil"
              element={
                <Profile
                  data={state.user}
                  PCConfiguration={state.pageConfiguration}
                  showAlerts={state.alert}
                />
              }
            />
            <Route
              exact
              path="/pageConfiguration"
              element={
                <PageConfigurationChat
                  pCInfo={state.pageConfiguration}
                  user={state.user}
                  showAlerts={state.alert}
                />
              }
            />
            <Route
                  exact
                  path="/sugerencias"
                  element={<Suggestions user={state.user} />}
                />
            <Route
              exact
              path="/presupuestos"
              element={
                <Budget
                  user={state.user}
                  budgets={state.budget}
                  pCInfo={state.pageConfiguration.PCInformation}
                />
              }
            />
            <Route
              exact
              path="/*"
              element={
                <Budget
                  user={state.user}
                  budgets={state.budget}
                  pCInfo={state.pageConfiguration.PCInformation}
                />
              }
            />
            <Route exact path="/instagramAuthentication" element={<InstagramAuthentication user={state.user}/>} />

            <Route exact path="/stripe" element={<StripeLinks />} />
          </Routes>
        </Col>
      </Row>
    </Container>

  );
}