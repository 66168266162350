import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import { DomainName } from "./components/domainName";
import ForgetPassword from "./components/forgetPassword";
import { Stripe } from "./components/Stripe/Stripe";
import { ProfileOperations } from "../../redux/profile/profileOperators";
import "../../assets/styles/css/index.css";
import iconSolidUserAlt from "../../assets/images/icon-solid-user-alt.svg";
import iconSolidEnvelope from "../../assets/images/icon-solid-envelope.svg";
import { ToastConfirmation } from "../Alerts/ToastConfirmation";
// import { DarkMode } from "./components/DarkMode/DarkMode";

export const Profile = ({ data, PCConfiguration, showAlerts }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const [showButton, setShowButton] = useState(true);

  const [userState, setUserState] = useState({
    name_user: data?.profile?.name_user,
    lastName: data?.profile?.last_name,
    phone: data?.profile?.phone,
    email: data?.profile?.email,
  });
  const handleChange = (value, name_user) => {
    setUserState({
      ...userState,
      [name_user]: value,
      id: data?.profile?.id,
    });
    setShowButton(false);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(
      ProfileOperations.updateProdile({
        user: userState,
        id: data?.profile?.id,
      })
    );

    setShowButton(true);
  };

  const onChangePwdSubmit = async (pass) => {
    dispatch(ProfileOperations.changePass({ pass, id: data?.profile?.id }));
  };

  return (
    <Container fluid translate="no" className="containerMyAccount">
      <Row>
        <Col>
          <div className="divTextMyAccount">
            <h2 className="headingH2 text-color">{t("profile.title")}</h2>
          </div>
        </Col>
        <hr className="color-hr"></hr>
      </Row>
      <Row>
        <Col className="colPersonalInfo">
          <div className="divPersonalInfo">
            <h4 className="headingH4 text-color">
              {t("editInformation.businessData")}
            </h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container color-shadow">
            <DomainName PCConfiguration={PCConfiguration} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colPersonalInfo">
          <div className="divPersonalInfo">
            <h4 className="headingH4 text-color">{t("profile.profile")}</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container">
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3" id="nombre">
                <Form.Label className="color-text-secondary">
                  {" "}
                  {t("profile.name")}{" "}
                </Form.Label>
                <div className="divMyAccount">
                  <img src={iconSolidUserAlt} alt="Icon nombre"></img>
                  <Form.Control
                    autoComplete="nope"
                    className="inputProfile color-text-secondary bg-input"
                    name="name_user"
                    placeholder={t("profile.nameBox")}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    type="text"
                    value={userState.name_user === null ? "" : userState.name_user}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" id="lastname">
                <Form.Label className="color-text-secondary">
                  {" "}
                  {t("profile.surname")}{" "}
                </Form.Label>
                <div className="divMyAccount">
                  <img src={iconSolidUserAlt} alt="Icon nombre"></img>
                  <Form.Control
                    autoComplete="nope"
                    className="inputProfile color-text-secondary bg-input"
                    placeholder={t("profile.surnameBox")}
                    name="lastName"
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    type="text"
                    value={
                      userState.lastName === null ? "" : userState.lastName
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" id="phone">
                <Form.Label className="color-text-secondary">
                  {" "}
                  {t("profile.phone")}{" "}
                </Form.Label>
                <div className="divMyAccount">
                  <ReactPhoneInput
                    id="idInputPhoneNumberForm"
                    className=" greyTextFormPhone color-text-secondary bg-input"
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    value={userState.phone === null ? "" : userState.phone}
                    placeholder={t("profile.phoneBox")}
                    required
                    country={"es"}
                    onChange={(e) => handleChange(e, "phone")}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4" id="email">
                <Form.Label className="color-text-secondary">
                  {" "}
                  {t("profile.email")}{" "}
                </Form.Label>
                <div className="divMyAccount">
                  <img src={iconSolidEnvelope} alt="Icono del nombre"></img>
                  <Form.Control
                    autoComplete="nope"
                    className="inputProfile color-text-secondary bg-input"
                    placeholder={t("profile.emailBox")}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    name="email"
                    type="email"
                    value={userState.email === null ? "" : userState.email}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </div>
              </Form.Group>
              {/* <DarkMode data={data} /> */}
              <div className="divBottonProfile">
                <Button
                  disabled={showButton}
                  className="bottonProfile bodyDetailsBold bg-button color-text-button"
                  type="submit"
                >
                  {t("profile.save")}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="colPersonalInfo">
          <div className="divPersonalInfo">
            <h4 className="headingH4 text-color">
            </h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="colPersonalInfo">
          <div className="divPersonalInfo">
            <h4 className="headingH4 text-color">
              {t("profile.subscription")}
            </h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container">
            <Stripe data={data} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colPersonalInfo">
          <div className="divPersonalInfo">
            <h4 className="headingH4 text-color">
              {t("updatePassword.title")}
            </h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container">
            <ForgetPassword onChangePwdSubmit={onChangePwdSubmit} />
          </div>
        </Col>
      </Row>
      <ToastConfirmation showAlerts={showAlerts} />
    </Container>
  );
};
